<template>
  <div>
    <div v-if="siteUrl === 'true'">
      <QuestionCall :phone='phone' :ticket="ticket"/>
    </div>
    <footer class="broker-footer">
      <div class="container">
        <div class="row">
          <div class="top-footer-menu ">
            <div>
              <template v-if="brokerExchangeLogo">
                <img :src="brokerExchangeLogo" class="broker-logo" />
                </template>
             <template v-else>
              <img src="../../src/assets/logo.png" class="broker-logo" />
             </template>
             <template v-if="footerIcon">
              <img :src="footerIcon" class="corenoll-logo" />
              </template>
              <template v-else>
                <img src="https://benefit-store-assets.s3.us-east-2.amazonaws.com/footer-img-left.png" class="corenoll-logo" />
              </template>
             
             
            </div>
            <div class="footer-menu">
              <nav class="be-footer-nav grp-quote-container">
                <a @click="routeToBrokerExchangeHome">Home</a>
                <a :href="menuLink.routeToGroupRegistration" target="_blank"
                  >Group Application</a
                >
                <a  v-if="checkPlatformRedirection()"
                  ><span
                    v-b-modal.employeeEnrollment-pop
                    @click="resetEnrollGroup()"
                    >Group Enrollment</span
                  ></a
                >
                <a :href="menuLink.routeToRepRegistration" target="_blank"
                  >Rep Registration</a
                >
                <a :href="menuLink.routeToRepDashboard" target="_blank"
                  >Rep Login</a
                >
                <a :href="menuLink.routeToGroupDashboard" target="_blank"
                  >Group Login</a
                >
                <a @click="routeToNotMySITE"
                  >Not My Site</a
                >
                <div>
                  <button class="grp-quote" @click="routeToGroupQuote">Group Quote</button>
                </div>

              </nav>
            </div>
          </div>
          <div class="row mt-3 ml-3">
            <div class="col-md-6 col-sm-12">
              <ul class="be-footer-list text-md-left">
                <li class="copyright">
                  © {{  new Date().getFullYear() }} BenAdvance. All Rights Reserved.
                </li>
              </ul>
            </div>
            <div
              class="col-md-6 col-sm-12 text-xl-end text-sm-end contact-details"
            >
              <ul class="be-footer-list text-md-right">
                <li>Phone: <a :href="`tel:${phone}`">{{phone}}</a></li>
                <li>
                  <a :href="`mailto:${email}`"
                    >{{email}}</a
                  >
                </li>
              </ul>
            </div>
          </div>
          <div class="col-xxl-12 col-md-12 col-12">
            <div class="app-wrap">
              <p>Download The Member App</p>
              <div class="app-icon">
                <a v-bind:href="iosLink" target="_blank"
                  ><img :src="iosImgLink" alt="iOS App" class="img-fluid"
                /></a>
                <a
                  v-bind:href="androidLink"
                  v-if="googlePlay == true"
                  target="_blank"
                  ><img
                    :src="androidImgLink"
                    alt="Android App"
                    class="img-fluid"
                /></a>
                <p>Click the Appropriate App</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>

    <!-- Access Code Pop up -->
    <b-modal id="accessCode-pop" centered size="md" v-model="accssShow">
      <template #modal-header>
        <div class="popUp-header">
          <h4>Access Code</h4>
          <b-button variant="close-btn" @click="accssShow = false">
            <i class="fas fa-times"></i>
          </b-button>
        </div>
      </template>
      <b-container fluid>
        <div v-if="!showRepConformation">
          <div class="form-group mb-3">
            <label>Rep Access Code</label>
            <input
              type="text"
              v-model="repAccessCode"
              @input="removeGroupErrorMessage()"
              :class="{
                'form-control is-invalid':
                  $v.repAccessCode.$error || repErrorFlag,
              }"
              class="custom-input"
              placeholder="Enter Rep Access Code Eg: ABCD1212"
            />
            <div
              class="form-group__message text-left ml-3 mt-2"
              v-if="!$v.repAccessCode.required && $v.repAccessCode.$error"
            >
              Field is required
            </div>
            <div class="error_message mt-2" v-if="repErrorFlag === true">
              Invalid Rep Access Code
            </div>
          </div>
          <div class="form-group">
            <button class="univ-btn" @click="checkRepEnrollmentCode()">
              Continue
            </button>
          </div>
        </div>
        <div v-if="showRepConformation">
          <div class="text-center mb-3">
            <div><strong>Confirm rep is correct</strong></div>
            <div class="mt-3">
              Do you want to enroll under
              <strong
                >Rep {{ repInfo.firstName }} {{ repInfo.lastName }}</strong
              >
              ?
            </div>
          </div>
          <b-row>
            <b-col cols="6" sm="6" md="6" lg="6" xl="6">
              <div class="form-group">
                <button class="univ-btn" @click="routeToRepEnroll">Yes</button>
              </div>
            </b-col>
            <b-col cols="6" sm="6" md="6" lg="6" xl="6">
              <button class="univ-btn" @click="resetRepEnroll">No</button>
            </b-col>
          </b-row>
        </div>
      </b-container>
    </b-modal>

    <!-- Employee Enrollment pop up -->
    <b-modal
      id="employeeEnrollment-pop"
      centered
      size="md"
      v-model="enrollmentShow"
    >
      <template #modal-header>
        <div class="popUp-header">
          <h4>Employee Enrollment</h4>
          <b-button variant="close-btn" @click="enrollmentShow = false">
            <i class="fas fa-times"></i>
          </b-button>
        </div>
      </template>
      <b-container fluid>
        <div v-if="!showProceedConformation">
          <div class="form-group mb-3">
            <label>Group Enrollment Code</label>
            <input
              type="text"
              v-model="groupEnrollmentCode"
              @input="removeGroupErrorMessage()"
              :class="{
                'form-control is-invalid':
                  $v.groupEnrollmentCode.$error || groupErrorFlag,
              }"
              class="custom-input"
              placeholder="Enter Group Enrollment CodeEg: ABCD1212"
            />
            <div
              class="form-group__message text-left ml-3 mt-2"
              v-if="
                !$v.groupEnrollmentCode.required &&
                $v.groupEnrollmentCode.$error
              "
            >
              Field is required
            </div>
            <div class="error_message mt-2" v-if="groupErrorFlag === true">
              {{ this.groupErrorMsg }}
            </div>
          </div>
          <div class="form-group semiText">
            <p>
              If you don't know your group enrollment code please contact your
              company's human resource department.
            </p>
          </div>
          <div class="form-group">
            <button class="univ-btn" @click="checkGroupEnrollmentCode()">
              Continue
            </button>
          </div>
        </div>
        <div v-if="showProceedConformation">
          <div class="text-left mb-3">
            <div class="mt-2">
              The company that you will be enrolling under is:<strong
                style="color: #28a745"
              >
                {{ groupInfo.name }}</strong
              >
            </div>
            <div
              class="mt-2 group-not-employer-container"
              @click="backToEnrollmentCode"
            >
              <p>
                If this is NOT the your employer please click here, to re-enter
                your employer enrollment code.
              </p>
              <p>
                If you have questions please contact your Employer for correct
                code.
              </p>
            </div>
          </div>
          <b-row>
            <b-col cols="6" sm="6" md="6" lg="6" xl="6">
              <div class="form-group">
                <button class="univ-btn" @click="routeToGroupEnroll">
                  Yes
                </button>
              </div>
            </b-col>
            <b-col cols="6" sm="6" md="6" lg="6" xl="6">
              <button class="univ-btn" @click="resetEnrollGroup">No</button>
            </b-col>
          </b-row>
        </div>
      </b-container>
    </b-modal>
  </div>
</template>
<script>
import axios from "axios";
import QuestionCall from "./QuestionCall.vue";
import { required } from "vuelidate/lib/validators";
import Helper from '../includes/Helper';
import {checkPlatform} from "../utils/checkPlatform";

export default {
  name: "SiteFooter",
  components: {
    QuestionCall,
  },
  data: function () {
    return {
      phone: "8882434011",
      email: "info@brokerexchanges.com",
      enrollmentShow: false,
      accssShow: false,
      ticket: "",
      footerIcon: "",
      iosImgLink: require('../assets/images/ios_icon.png'),
      androidImgLink: require('../assets/images/google_icon.png'),
      iosLink: "https://apps.apple.com/us/app/corenroll-member/id1539841827",
      androidLink:
        "https://play.google.com/store/apps/details?id=com.neura.corenroll_member&hl=en&gl=US",
      open_type: "",
      group_id: "",
      agent_id: "",
      googlePlay: true,
      siteUrl: process.env.VUE_APP_IS_GO_ENROLL,
      showProceedConformation: false,
      groupEnrollmentCode: null,
      groupRouteName: null,
      groupErrorFlag: false,
      groupErrorMsg:'',
      showRepConformation: false,
      repAccessCode: null,
      repRouteName: null,
      repErrorFlag: false,
      routeToGroupRegistration: process.env.VUE_APP_GROUP_REGISTRATION,
      routeToMemberDashboard: process.env.VUE_APP_MEMBER_DASHBOARD,
      repInfo: {
        firstName: "",
        lastName: "",
      },
      groupInfo: {
        name: "",
      },
      menuLink: {
        routeToAdmin: process.env.VUE_APP_ADMIN,
        routeToMessageCenter: process.env.VUE_APP_MESSAGE_CENTER,
        routeToFileManagement: process.env.VUE_APP_FILE_MANAGEMENT,
        routeToRepDashboard: process.env.VUE_APP_REP_DASHBOARD,
        routeToMemberDashboard: process.env.VUE_APP_MEMBER_DASHBOARD,
        routeToGroupDashboard: process.env.VUE_APP_GROUP_DASHBOARD,
        routeToTicketManagement: process.env.VUE_APP_TICKET_MANAGEMENT,
        routeToSos: process.env.VUE_APP_SOS,
        routeToRepRegistration: `${process.env.VUE_APP_REP_REGISTRATION}${
          localStorage.getItem("agent_code")
            ? `?repCode=${localStorage.getItem("agent_code")}`
            : ""
        }`,
        routeToMemberRegistration: process.env.VUE_APP_MEMEBER_REGISTRATION,
        routeToGroupRegistration: `${process.env.VUE_APP_GROUP_REGISTRATION}${
          localStorage.getItem("agent_code")
            ? `?repCode=${localStorage.getItem("agent_code")}`
            : ""
        }`,
      },
      show: false,
      groupQuoteLink: process.env.VUE_APP_GROUP_QUOTE_LINK,
      videoLink: "",
      modelId: "",
      brokerExchangeLogo:
        "https://enrollmentlogo.s3.us-east-2.amazonaws.com/benAdvancelogo.png",
      platformType: ''
    };
  },
  validations: {
    repAccessCode: {
      required,
    },
    groupEnrollmentCode: {
      required,
    },
  },
  mounted() {
    let app = this;
    app.group_id = window.localStorage.getItem("group_id");
    app.agent_id = window.localStorage.getItem("agent_id");
    app.open_type = window.localStorage.getItem("open_type");
    app.eprocess = window.localStorage.getItem("eprocess");
    app.platformType = localStorage.getItem('platformType');
    if (app.open_type === "group" || app.eprocess === "group") {
      app.getGroupHeaderInfo();
    } else {
      //open landing pages
      app.getAgentHeaderInfo();
    }
    if (
      app.$device.ios ||
      app.$device.iphone ||
      app.$device.iphoneX ||
      app.$device.iPhoneXR ||
      app.$device.iPhoneXSMax ||
      app.$device.ipod ||
      app.$device.ipad ||
      navigator.platform.indexOf("Mac") > -1
    ) {
      app.googlePlay = false;
    }
    app.$root.$on("infoLanding", (info) => {
      app.phone = info.phone;
      app.email = info.email;
      app.getTicketLink();
    });
    app.fetchCatchInfo();
    if (location.host == process.env.VUE_APP_SITE_HOST_NAME) {
      app.getTicketLink();
    }
  },
  methods: {
    getTicketLink: function () {
      let app = this;
      axios
        .get(process.env.VUE_APP_API_BASE + "/get-ticket-link")
        .then(function (response) {
          app.ticket = response.data.data["ticket"];
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    routeToGroupQuote(){
      let app = this
      const baseUrl = app.groupQuoteLink
      const domainName = location.host
      const queryParams = {
        'platform_name': domainName
      }
      const queryString = new URLSearchParams(queryParams).toString();
      const fullUrl = `${baseUrl}?${queryString}`;
      window.open(fullUrl,'_blank');
    },
    getAgentHeaderInfo: function () {
      let app = this;
      axios
        .get(process.env.VUE_APP_API_BASE + "/get-agent-landing-page-info", {
          params: {
            agent_id: app.agent_id,
          },
        })
        .then(function (response) {
          window.localStorage.setItem(
            "agent_code",
            response.data.data.agent_code
          );
          app.menuLink.routeToRepRegistration = `${
            process.env.VUE_APP_REP_REGISTRATION
          }${
            localStorage.getItem("agent_code")
              ? `?repCode=${localStorage.getItem("agent_code")}`
              : ""
          }`;
          app.menuLink.routeToGroupRegistration = `${
            process.env.VUE_APP_GROUP_REGISTRATION
          }${
            localStorage.getItem("agent_code")
              ? `?repCode=${localStorage.getItem("agent_code")}`
              : ""
          }`;
          app.phone = response.data.data.display_phone;
          app.email = response.data.data.display_email;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getGroupHeaderInfo: function () {
      let app = this;
      axios
        .get(process.env.VUE_APP_API_BASE + "/get-group-landing-page-info", {
          params: {
            group_id: app.group_id,
          },
        })
        .then(function (response) {
          window.localStorage.setItem(
            "agent_code",
            response.data.data.agent_code
          );
          app.menuLink.routeToGroupRegistration = `${
            process.env.VUE_APP_GROUP_REGISTRATION
          }${
            localStorage.getItem("agent_code")
              ? `?repCode=${localStorage.getItem("agent_code")}`
              : ""
          }`;
          app.menuLink.routeToRepRegistration = `${
            process.env.VUE_APP_REP_REGISTRATION
          }${
            localStorage.getItem("agent_code")
              ? `?repCode=${localStorage.getItem("agent_code")}`
              : ""
          }`;
          app.phone = response.data.data.display_phone;
          app.email = response.data.data.display_email;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    fetchCatchInfo() {
      let siteDomain = window.btoa(
        unescape(encodeURIComponent(process.env.VUE_APP_SITE_DOMAIN))
      );
      // let siteDomain = 'aHR0cHM6Ly9lbnJvbGwucHVyZW5yb2xsLmNvbQ=='
      let url = `${process.env.VUE_APP_API_BASE}/get-external-site-dynamic-information?site_domain=${siteDomain}`;
      caches
        .open("fetch_color_cache")
        .then((cache) => {
          cache.match(url).then((response) => {
            response.json().then((data) => {
              let getCatchColor = [];
              let newObj = {};
              getCatchColor = data;
              getCatchColor.map((item) => {
                newObj[item.key] = item.value;
              });
              this.footerIcon = newObj["VUE_APP_FOOTER_LOGO"];
              this.iosImgLink = newObj["VUE_APP_IOS_ICON"];
              this.androidImgLink = newObj["VUE_APP_ANDROID_ICON"];
            });
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    checkGroupEnrollmentCode() {
      let app = this;
      app.$v.groupEnrollmentCode.$touch();
      if (app.$v.groupEnrollmentCode.$invalid) {
        return false;
      } else {
        axios
          .get(process.env.VUE_APP_API_BASE + "/validate-group", {
            params: {
              group_code: app.groupEnrollmentCode,
            },
          })
          .then(function (response) {
            if (response.data.status == "success") {
              app.groupInfo.name = response.data.data.name;
              app.showProceedConformation = true;
            }
            if (response.status == 204) {
              app.groupErrorFlag = true;
              app.showProceedConformation = false;
            }
          })
          .catch(function (error) {
            app.groupErrorFlag = true;            
            app.showProceedConformation = false;
            app.groupErrorMsg= error.response.data.message
          });
      }
    },
    resetEnrollGroup() {
      let app = this;
      app.showProceedConformation = false;
      app.enrollmentShow = false;
      app.groupEnrollmentCode = null;
      app.groupErrorFlag = false;
      app.$v.$reset();
    },
    routeToGroupEnroll() {
      let app = this;
      axios
        .get(process.env.VUE_APP_API_BASE + "/validate-group", {
          params: {
            group_code: app.groupEnrollmentCode,
          },
        })
        .then(function (response) {
          if (response.data.status == "success") {
            let responseData = response.data.data;
            app.groupRouteName = responseData.landing_url;
            app.enrollmentShow = false;
            let enrollType = "Group";
            if (
              responseData.landing_url == null ||
              responseData.landing_url == ""
            ) {
              app.$router.push("/landing-config-error/" + enrollType);
            } else {
              app.$router.push(`/${app.groupRouteName}`);
              location.reload();
            }
          }
          if (response.status == 204) {
            app.groupErrorFlag = true;
            app.showProceedConformation = false;
          }
        })
        .catch(function (error) {
          app.groupErrorFlag = true;
          app.showProceedConformation = false;
          app.groupErrorMsg=error.response.data.message
        });
    },
    removeGroupErrorMessage() {
      let app = this;
      app.groupErrorFlag = false;
      app.repErrorFlag = false;
    },
    checkRepEnrollmentCode() {
      let app = this;
      app.$v.repAccessCode.$touch();
      if (app.$v.repAccessCode.$invalid) {
        return false;
      } else {
        let app = this;
        axios
          .get(process.env.VUE_APP_API_BASE + "/validate-agent", {
            params: {
              agent_code: app.repAccessCode,
            },
          })
          .then(function (response) {
            if (response.data.status == "success") {
              app.showRepConformation = true;
              app.repInfo.firstName = response.data.data.first_name;
              app.repInfo.lastName = response.data.data.last_name;
            }
            if (response.status == 204) {
              app.repErrorFlag = true;
              app.showRepConformation = false;
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
    routeToRepEnroll() {
      let app = this;
      axios
        .get(process.env.VUE_APP_API_BASE + "/validate-agent", {
          params: {
            agent_code: app.repAccessCode,
          },
        })
        .then(function (response) {
          if (response.data.status == "success") {
            let responseData = response.data.data;
            app.repRouteName = responseData.landing_url;
            app.enrollmentShow = false;
            let enrollType = "Agent";
            if (
              responseData.landing_url == null ||
              responseData.landing_url == ""
            ) {
              app.$router.push("/landing-config-error/" + enrollType);
            } else {
              app.$router.push(`/${app.repRouteName}`);
              location.reload();
            }
          }
          if (response.status == 204) {
            app.repErrorFlag = true;
            app.showRepConformation = false;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    resetRepEnroll() {
      let app = this;
      app.showRepConformation = false;
      app.accssShow = false;
      app.repAccessCode = null;
      app.repErrorFlag = false;
      app.$v.$reset();
    },
    backToEnrollmentCode() {
      let app = this;
      app.showProceedConformation = false;
      app.groupEnrollmentCode = null;
      app.$v.$reset();
    },
    routeToBrokerExchangeHome() {
      let app = this;
      app.$router.push("/");
    },
    routeToNotMySITE() {
      Helper.redirectToAcessPage(this)
    },
    checkPlatformRedirection () {
      let app = this
      return (checkPlatform(app.platformType))
    }
  },
  listener: {
    FromDirect() {
      let app = this;
      app.group_id = window.localStorage.getItem("group_id");
      app.agent_id = window.localStorage.getItem("agent_id");
      if (app.open_type === "group" || app.eprocess === "group") {
        app.getGroupHeaderInfo();
      } else {
        app.getAgentHeaderInfo();
      }
    },
    externalsite() {
      let app = this;
      if (app.open_type === "group" || app.eprocess === "group") {
        app.getGroupHeaderInfo();
      } else {
        app.getAgentHeaderInfo();
      }
    },
  },
};
</script>

<style lang="scss">
.group-not-employer-container {
  p {
    color: #dc3545;
    cursor: pointer;
    margin-bottom: 0;
    font-size: 14px;
  }
  &:hover {
    text-decoration: underline;
    color: #dc3545;
  }
}
.be-footer-nav a {
  color: white !important;
  text-transform: uppercase;
  font-weight: 600;
  margin: 0 10px 12px 10px;
  display: inline-block;
  vertical-align: top;
  font-size: 13px;
}
.be-footer-nav a:hover,
footer ul li a:hover {
  color: #a5cdfb !important;
  text-decoration: underline !important;
}
.be-footer-nav {
  padding-right: 1.8rem;
  margin-top: 0.8rem;
}
.broker-logo {
  width: 230px;
  margin-right: 2rem;
  padding: 13px 0;
}
.corenoll-logo {
  width: 124px;
}
.top-footer-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.contact-details {
  display: flex;
  justify-content: end;
}
.copyright {
  margin-left: 5px;
  font-size: 13px;
}
.app-wrap {
  display: grid;
  justify-content: center;
  text-align: center;
}
footer ul li a {
  color: white !important;
}
.broker-footer {
  padding-top: 40px;
  padding-bottom: 0px;
}
.copyright:first-child {
  border-right: none;
}
.grp-quote{
  font-size: 14px;
  color: #fff;
  background: #0099CC;
  padding: 10px 30px;
  border-radius: 6px;
  display: inline-block;
  transition: all ease 0.4s;
  border: none;
  margin-right: 0.5rem;
}

.grp-quote-container{
  display: flex;
  align-items: center;
  justify-content: center;
}
.grp-quote{
  margin-top: -15px;
  margin-left: 10px;
}
@media (max-width: 1200px) {
  .corenoll-logo {
    margin-top: 1rem;
  }
}
@media (max-width: 1199px) {
  .be-footer-nav a{
    margin: 0 10px 20px 10px;
  }
  .grp-quote{
    margin-top: -0.8rem;
  }
}
@media (max-width: 992px) {
  .top-footer-menu {
    display: block;
  }
  .footer-menu {
    margin-top: 1rem;
  }
  .be-footer-nav {
    margin-left: -0.2rem;
  }
  .be-footer-list {
    padding-left: 0.2rem;
  }
  .contact-details {
    justify-content: flex-start;
  }
  .copyright {
    margin-left: 3px;
  }
}
@media (max-width: 767px) {
  .app-wrap {
    justify-content: start;
    text-align: left;
  }
  .help-button {
    margin: 1rem;
  }
  .app-wrap {
    margin-left: 7px;
  }
  .broker-logo {
    margin-left: 0.1rem;
  }
  .copyright {
    margin-left: 4px;
  }
  .grp-quote-container{
    display: grid;
    align-items: center;
    justify-content: center;
  }
}
</style>

<style>
.grp-quote-container{
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: right;
}
.grp-quote{
  margin-top: -15px;
  margin-left: 10px;
}
@media (max-width:767px) {
  .grp-quote-container{
    display: block;
    text-align: left;
  }
  
}
</style>
