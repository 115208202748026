<template>
    <div>
      <button
        type="button"
        name="button"
        class="button-danger me-2"
        @click="renderConformationPopup"
      >
        Cancel Enrollment
      </button>
      <b-modal
        id="cancel-enrollment"
        centered
        no-close-on-backdrop
        size="md"
        v-model="show"
      >
        <template #modal-header>
          <div class="popUp-header">
            <h4 class="text-center">
              Are you sure you want to cancel the enrollment?
            </h4>
            <b-button variant="close-btn" @click="closePopupModel">
              <i class="fas fa-times"></i>
            </b-button>
          </div>
        </template>
        <b-container fluid>
          <div class="popUp-details text-center">
            <div class="action-btn text-center mt-4">
              <b-button
                style="width: 40%"
                class="button-brand mx-1"
                @click="cancelEnrollment"
                data-dismiss="modal"
                >Yes</b-button
              >
              <b-button
                style="width: 40%"
                class="dark-btn mx-1"
                @click="closePopupModel"
                >No</b-button
              >
            </div>
          </div>
        </b-container>
      </b-modal>
    </div>
  </template>
  
  <script>
  export default {
    name: "CancelEnrollmentButton",
    data() {
      return {
        show: false,
      };
    },
    computed: {
      landingInfo() {
        return this.$store.getters.getLandingInfo;
      },
    },
    methods: {
      closePopupModel() {
        let app = this;
        app.show = false;
      },
      renderConformationPopup() {
        let app = this;
        app.show = true;
      },
      cancelEnrollment() {
        let app = this;
        window.localStorage.removeItem("tempId");
        window.localStorage.removeItem("cartCount");
        app.$router.push("/plans");
        app.$bus.$emit("resetCartInfo");
      },
    },
  };
  </script>
  
  <style scoped></style>