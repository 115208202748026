<template>
    <div id="app">
      <div v-if="defaultHeader">
        <site-header />
      </div>
      <loader v-if="showLoading"></loader>
      <router-view/>
      <div v-if="defaultFooter">
        <site-footer />
      </div>
      <location-permission />
      <notifications></notifications>
    </div>
</template>

<script>
import SiteHeader from './components/SiteHeader.vue'
import SiteFooter from './components/SiteFooter.vue'
import loader from './components/LoaderGlobal.vue'
import LocationPermission from './components/LocationPermission.vue'
import { mapState } from 'vuex';
export default {
  components: {
    SiteHeader,
    SiteFooter,
    loader,
    LocationPermission
  },
  mounted () {
    this.FetchColorCodeInfo();
    this.renderFavIcon();
    this.disableGoogleTranslate();
  },
  data(){
    return{
      defaultHeader: true,
      defaultFooter: true,
    }
  },
  methods: {
    FetchColorCodeInfo () {
      console.log('*** FetchColorCode ***')
      // let siteDomain = window.btoa(unescape(encodeURIComponent('https://enroll.purenroll.com')))
      let siteDomain = window.btoa(unescape(encodeURIComponent(process.env.VUE_APP_SITE_DOMAIN)))
      // let siteDomain = 'aHR0cHM6Ly9lbnJvbGwucHVyZW5yb2xsLmNvbQ=='
      let url = `${process.env.VUE_APP_API_BASE}/get-external-site-dynamic-information?site_domain=${siteDomain}`
      // passing request object
      let req = new Request(url, {
        method: 'GET',
        headers: {
          'Content-Type': process.env.VUE_APP_CONTENT_TYPE,
          'Authorization': process.env.VUE_APP_AUTHORIZATION
        },
        mode: 'cors'
      })
      // Using fetch api javascript in built library for api req
      fetch(req)
          .then(response => {
            response.json().then(data => {
              // this.getCatchColor = JSON.stringify(data.data)
              // console.log('fetch response', JSON.stringify(data.data))

              // Creating cache name fetch_color_cache
              caches.open('fetch_color_cache').then((cache) => {
                // Converting response send as promise to Json object.
                const jsonResponse = new Response(JSON.stringify(data.data));
                // Storing the response on created catch
                cache.put(req, jsonResponse);
              }).catch((err) => {
                console.log(err);
              })

            })
          })
          .catch(error => {
            console.log(error);
          });
    },
    renderFavIcon() {
      if (process.env.VUE_APP_IS_GO_ENROLL == 'true') {
        let favicon = document.getElementById("favicon");
        favicon.href = 'goenroll-fav.ico'
        document.title = 'Broker Exchanges'
      } else {
        let favicon = document.getElementById("favicon");
        favicon.href = 'favicon.png'
        document.title = 'Easiest Enrollment System - Corenroll'
      }
    },
    disableGoogleTranslate () {
     if (process.env.VUE_APP_IS_GO_ENROLL == 'false') {
       let element = document.getElementById('google_translate_element');
       element.style.display = 'none'
     }
    }
  },
  watch: {
    "$route.params": {
      handler() {
        if (this.$route.name == "error" || this.$route.name == 'decline-reminder-email' || this.$route.name == "landing-config-error") {
          this.defaultHeader = false;
          this.defaultFooter = false;
        } else {
          this.defaultHeader = true;
          this.defaultFooter = true;
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapState({
      showLoading: state => state.showLoading
    })
  }
}
</script>

<style>
@import url('assets/fonts/stylesheet.css');
@import url('assets/css/common.css');
#app {
  font-family: 'Raleway' !important;
}
body{
  background-color: #f2f2f2 !important;
  position: relative;
}
body::before{
  content: '';
  position: absolute;
  /* background: url(./assets/body-bg.png) no-repeat; */
  width: 100%;
  height: 100%;
  background-size: inherit;
  min-height: 100vh;
  background-position: right top;
  z-index: -1;
  background-attachment: fixed;
}
a, a:hover{
  color: inherit;
  text-decoration: none !important;
}
</style>

<style lang="scss" >
    //#app {
    //    font-family: Avenir, Helvetica, Arial, sans-serif;
    //    -webkit-font-smoothing: antialiased;
    //    -moz-osx-font-smoothing: grayscale;
    //    text-align: center;
    //    color: #2c3e50;
    //}
    // @import "css/common.css";
    // @import "css/index.css";
    /*@import "css/main.css";*/
    //@import "css/plan_desc.css";
    //@import "scss/main";

    // @import "assets/scss/main.scss";
    .vue-notifyjs {
      .alert {
        z-index: 1080 !important;

        &.alert-with-icon {
          padding-left: 25px !important;
        }

        .alert-icon {
          margin-right: 15px !important;
        }

        .close ~ span {
          display: inline-block !important;
        }
      }
    }

</style>
